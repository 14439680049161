import * as React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";

import PageWrapper from "../components/pageWrapper/PageWrapper";
import localize from "../hoc/Localize";
import "./policy.scss";

export interface Props {
    data: {
        sanityLegal: {
            title: string;
            _rawTerms: unknown;
        };
    };
}

const TermsOfServicePage: React.FunctionComponent<Props> = props => {
    const { sanityLegal } = props.data;
    return (
        <PageWrapper {...props} className="policy__layout">
            <div className="policy__container">
                <h1 style={{ textAlign: "center" }}>{sanityLegal.title}</h1>

                <section
                    style={{
                        margin: "0 auto",
                        maxWidth: "960px",
                        padding: "16px 16px 72px",
                    }}
                >
                    <BlockContent blocks={sanityLegal._rawTerms} />
                </section>
            </div>
        </PageWrapper>
    );
};

export const query = graphql`
    query termsQuery {
        sanityLegal(slug: { eq: "terms-of-service" }) {
            title {
                _type
                en
                es
            }
            _rawTerms
        }
    }
`;

export default localize(TermsOfServicePage);
